.summary-card {
    background-color: $color-background-highlight;
    border: 1px solid $color-secondary;
    border-radius: 5px;
    &__label, &__value {
        font-weight: 700;
        font-family: 'Open Sans', sans-serif;
    }
    &__label {
        color: $color-primary;
    }
    &__value {
        color: $color-gray-darker;
    }
}

.date-card {
    line-height: 1;
    &__month {
        background-color: $color-primary;
        color: $color-white;
        padding: 6px 8px;
        text-align: center;
        font-size: 14px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        &.breakfast {
            background-color: $color-event-type-breakfast;
        }
        &.continental-breakfast {
            background-color: $color-event-type-continental-breakfast;
        }
        &.dinner {
            background-color: $color-event-type-dinner;
        }
        &.hors-doeuvres {
            background-color: $color-event-type-hors-doeuvres;
        }
        &.live-webinar {
            background-color: $color-event-type-live-webinar;
        }
        &.lunch {
            background-color: $color-event-type-lunch;
        }
        &.other {
            background-color: $color-event-type-other;
        }
        &.refreshments {
            background-color: $color-event-type-refreshments;
        }
    }
    &__date {
        background-color: $color-accent-light;
        color: $color-extra;
        padding: 6px 8px;
        font-size: 18px;
        text-align: center;
        border: 1px solid $color-primary;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        &.breakfast {
            background-color: $color-event-type-breakfast-light;
            border-color: $color-event-type-breakfast;
        }
        &.continental-breakfast {
            background-color: $color-event-type-continental-breakfast-light;
            border-color: $color-event-type-continental-breakfast;
        }
        &.dinner {
            background-color: $color-event-type-dinner-light;
            border-color: $color-event-type-dinner;
        }
        &.hors-doeuvres {
            background-color: $color-event-type-hors-doeuvres-light;
            border-color: $color-event-type-hors-doeuvres;
        }
        &.live-webinar {
            background-color: $color-event-type-live-webinar-light;
            border-color: $color-event-type-live-webinar;
        }
        &.lunch {
            background-color: $color-event-type-lunch-light;
            border-color: $color-event-type-lunch;
        }
        &.other {
            background-color: $color-event-type-other-light;
            border-color: $color-event-type-other;
        }
        &.refreshments {
            background-color: $color-event-type-refreshments-light;
            border-color: $color-event-type-refreshments;
        }
    }
}

.venue-card {
    &__top {
        display: grid;
        align-items: center;
        background-color: $color-event-type-other;
        color: $color-white;
        padding: 5px 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        grid-template-columns: 40px 1fr;
        gap: 10px;
        &.breakfast {
            background-color: $color-event-type-breakfast;
        }
        &.continental-breakfast {
            background-color: $color-event-type-continental-breakfast;
        }
        &.dinner {
            background-color: $color-event-type-dinner;
        }
        &.hors-doeuvres {
            background-color: $color-event-type-hors-doeuvres;
        }
        &.live-webinar {
            background-color: $color-event-type-live-webinar;
        }
        &.lunch {
            background-color: $color-event-type-lunch;
        }
        &.other {
            background-color: $color-event-type-other;
        }
        &.refreshments {
            background-color: $color-event-type-refreshments;
        }
    }
    &__middle {
        border-left: 1px solid $color-event-type-other;
        border-right: 1px solid $color-event-type-other;
        padding: 5px 10px;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    &__bottom {
        padding: 5px 10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border: 1px solid $color-event-type-other;
        &.breakfast {
            background-color: $color-event-type-breakfast-light;
        }
        &.continental-breakfast {
            background-color: $color-event-type-continental-breakfast-light;
        }
        &.dinner {
            background-color: $color-event-type-dinner-light;
        }
        &.hors-doeuvres {
            background-color: $color-event-type-hors-doeuvres-light;
        }
        &.live-webinar {
            background-color: $color-event-type-live-webinar-light;
        }
        &.lunch {
            background-color: $color-event-type-lunch-light;
        }
        &.other {
            background-color: $color-event-type-other-light;
        }
        &.refreshments {
            background-color: $color-event-type-refreshments-light;
        }
    }
    &__middle, &__bottom {
        &.breakfast {
            border-color: $color-event-type-breakfast;
        }
        &.continental-breakfast {
            border-color: $color-event-type-continental-breakfast;
        }
        &.dinner {
            border-color: $color-event-type-dinner;
        }
        &.hors-doeuvres {
            border-color: $color-event-type-hors-doeuvres;
        }
        &.live-webinar {
            border-color: $color-event-type-live-webinar;
        }
        &.lunch {
            border-color: $color-event-type-lunch;
        }
        &.other {
            border-color: $color-event-type-other;
        }
        &.refreshments {
            border-color: $color-event-type-refreshments;
        }
    }
    &__date {
        line-height: 1.2;
    }
    &__title {
        color: $color-primary;
        font-size: 18px;
        line-height: 1.2;
        font-weight: 600;
        font-style: italic;
    }
    @media (min-width: 1200px) {
        &__top {
            padding: 8px 10px;
        }
        &__middle {
            padding: 10px;
        }
        &__bottom {
            padding: 8px 10px;
        }
    }
}

.basic-panel {
    border-radius: $border-radius-card;
    border: 1px solid $color-secondary;
    box-shadow: $box-shadow-main;
    &__header {
        display: flex;
        color: $color-primary;
        font-size: 22px;
        font-weight: bold;
        line-height: 26px;
        padding: 10px 10px 10px 15px;
        background-color: $color-secondary-lighter;
        border-bottom: 1px solid $color-secondary;
        border-top-left-radius: $border-radius-card;
        border-top-right-radius: $border-radius-card;
    }
    &__content {
        padding: 15px;
    }
}





.brand--leadstar {
    .summary-card {
        border: 1px solid $color-brand-leadstar-secondary;
        &__label {
            color: $color-brand-leadstar-primary;
        }
    }    
    .date-card {
        &__month {
            background-color: $color-brand-leadstar-primary;
        }
        &__date {
            background-color: $color-accent-light;
            border: 1px solid $color-brand-leadstar-primary;
        }
    }    
    .venue-card {
        &__title {
            color: $color-brand-leadstar-primary;
        }
    }    
    .basic-panel {
        border-radius: $border-radius-card;
        border: 1px solid $color-brand-leadstar-secondary;
        &__header {
            color: $color-brand-leadstar-primary;
            background-color: $color-brand-leadstar-secondary-lighter;
            border-bottom: 1px solid $color-brand-leadstar-secondary;
        }
    }    
}





.date-card {
    &__month {
        &.breakfast {
            background-color: $color-event-type-breakfast;
        }
        &.continental-breakfast {
            background-color: $color-event-type-continental-breakfast;
        }
        &.dinner {
            background-color: $color-event-type-dinner;
        }
        &.hors-doeuvres {
            background-color: $color-event-type-hors-doeuvres;
        }
        &.live-webinar {
            background-color: $color-event-type-live-webinar;
        }
        &.lunch {
            background-color: $color-event-type-lunch;
        }
        &.other {
            background-color: $color-event-type-other;
        }
        &.refreshments {
            background-color: $color-event-type-refreshments;
        }
    }
    &__date {
        &.breakfast {
            background-color: $color-event-type-breakfast-light;
            border-color: $color-event-type-breakfast;
        }
        &.continental-breakfast {
            background-color: $color-event-type-continental-breakfast-light;
            border-color: $color-event-type-continental-breakfast;
        }
        &.dinner {
            background-color: $color-event-type-dinner-light;
            border-color: $color-event-type-dinner;
        }
        &.hors-doeuvres {
            background-color: $color-event-type-hors-doeuvres-light;
            border-color: $color-event-type-hors-doeuvres;
        }
        &.live-webinar {
            background-color: $color-event-type-live-webinar-light;
            border-color: $color-event-type-live-webinar;
        }
        &.lunch {
            background-color: $color-event-type-lunch-light;
            border-color: $color-event-type-lunch;
        }
        &.other {
            background-color: $color-event-type-other-light;
            border-color: $color-event-type-other;
        }
        &.refreshments {
            background-color: $color-event-type-refreshments-light;
            border-color: $color-event-type-refreshments;
        }
    }
}