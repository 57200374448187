.input-control {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    padding: 12px 5px;
    border-radius: 0 !important;
    border: 1px solid;
    width: 100%;
    transition: .3s linear all;
    border-color: $color-secondary;
    color: $color-gray-darker;
    background-color: $color-white;
    border-radius: 0 !important;
    &--medium {
        padding: 8px 5px;
    }
    &--basic {
        padding: 6px 4px;
    }
    &--small {
        font-size: 13px;
    }
    &--checkbox {
        accent-color: $color-primary;
        width: 20px;
        height: 20px;
        &.input-control--small {
            width: 17px !important;
            height: 17px !important;
        }
    }
    &:focus {
        border-color: $color-primary-light;
        background-color: $color-secondary-lighter;
        border-radius: 0 !important;
        outline: none;
    }
    &:not(:valid) {
        &:not(.protected) {
            color: $color-accent-dark;
            background-color: $color-accent-light;
            border-radius: 0 !important;
        }
    }
    &:disabled, &.disabled {
        &:not(.protected) {
            background-color: $color-gray-light;
            color: $color-gray;
            border-radius: 0 !important;
            border-color: $color-gray;    
        }
    }
    &--basic-check {
        accent-color: #fff;
    }
}


.brand--leadstar {
    .input-control {
        border-color: $color-brand-leadstar-secondary;
        &--checkbox {
            accent-color: $color-brand-leadstar-primary;
        }
        &:focus {
            border-color: $color-brand-leadstar-secondary;
            background-color: $color-brand-leadstar-secondary-lighter;
        }
    }    
}