.text-primary {
    color: $color-primary;
}

.text-secondary {
    color: $color-secondary;
}

.text-teriary {
    color: $color-tertiary;
}

.text-extra {
    color: $color-extra;
}

.text-status-cancelled {
    color: $color-status-cancelled;
}

.text-status-checked-in {
    color: $color-status-checked-in;
}

.text-status-disqualify {
    color: $color-status-disqualified;
}

.text-status-reserved {
    color: $color-status-reserved;
}

.text-status-reserved {
    color: $color-status-reserved;
}

.text-status-wait-list {
    color: $color-status-wait-list;
}