@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800);

$font-family-main: 'Open Sans', sans-serif;

$box-shadow-main: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

$widthMenuBreak: 1400px;
$widthMenuPreBreak: $widthMenuBreak - 1;

$widthMobileBreak: 641px;
$widthTabletBreak: 768px;
$widthLaptopBreak: 900px;
$widthDesktopBreak: 1024px;
$widthMediumDesktopBreak: 1200px;
$widthLargeBreak: 1650px;
$widthExtraLargeBreak: 2200px;

$zoomMainExtraLarge: 120%;
$zoomMainLarge: 100%;
$zoomMain: 90%;
$zoomMainSmall: 75%;

$color-primary: #124457;
$color-primary-light: #1b6581;
$color-secondary: #6aa2b9;
$color-secondary-light: #bdd8e3;
$color-secondary-lighter: #e1edf2;
$color-accent: #ee3b3b;
$color-accent-dark: #b31010;
$color-accent-light: #ffe8e8;
// $color-tertiary: #398896;
$color-tertiary: #076554;
$color-tertiary-light: #C6FBF1;
$color-tertiary-lighter: #ECFEFA;
$color-tertiary-dark: #04392F;
$color-quad: #88cfb6;
$color-quad-lighter: #f3faf8;
$color-extra: #521945;
$color-extra-light: #7D2669;

$color-black: #000;
$color-gray-darker: #222;
$color-gray-dark: #555;
$color-gray: #999;
$color-gray-light: #ccc;
$color-gray-lighter: #f2f2f2;
$color-gray-lightest: #fafafa;
$color-white: #fff;

$color-background-highlight: #fdf4ed;

$border-radius-button: 5px;
$border-radius-card: 5px;
$border-radius-dialog: 5px;
$border-radius-pill: 200px;

$color-cancelled: #e13636;
$color-checked-in: #6ec577;
$color-disqualified: #2b4f77;
$color-future-event: #8999cf;
$color-reserved: #6aa2b9;
$color-waitlist: #ee7f3b;
$color-error: #FF0000;

$color-bg-cancelled: #fbe1e1;
$color-bg-checked-in: #e9f6eb;
$color-bg-disqualified: #e9edf1;
$color-bg-future-event: #e7ebf5;
$color-bg-reserved: #e1edf2;
$color-bg-wait-list: #fce5d8;

$color-status-cancelled: #bb1c1c;
$color-status-cancelled-dark: #a30000;
$color-status-cancelled-light: #ffadad;
$color-status-cancelled-lighter: #ffebeb;
$color-status-cancelled-text: $color-white;

$color-status-checked-in: #007A00;
$color-status-checked-in-dark: #007a00;
// $color-status-checked-in-light: #adffad;
$color-status-checked-in-light: #C0F1C0;
$color-status-checked-in-lighter: #ebffeb;
$color-status-checked-in-text: $color-white;

$color-status-disqualified: #555555;
$color-status-disqualified-dark: #555555;
$color-status-disqualified-light: #cccccc;
$color-status-disqualified-lighter: #efefef;
$color-status-disqualified-text: $color-white;

$color-status-future-event: #5d2e8c;
$color-status-future-event-dark: #5d2e8c;
$color-status-future-event-light: #c2a3e1;
$color-status-future-event-lighter: #ebe0f5;
$color-status-future-event-text: $color-white;

$color-status-reserved: #0742b0;
$color-status-reserved-dark: #0742b0;
$color-status-reserved-light: #b1ccfb;
$color-status-reserved-lighter: #ebf2fe;
$color-status-reserved-text: $color-white;

$color-status-wait-list: #ff7d19;
$color-status-wait-list-dark: #a34700;
$color-status-wait-list-light: #ffc599;
$color-status-wait-list-lighter: #ffe7d6;
$color-status-wait-list-text: $color-black;

$color-status-nonbillable: #bb1c1c;
$color-status-nonbillable-dark: #a30000;
$color-status-nonbillable-light: #ffadad;
$color-status-nonbillable-lighter: #ffebeb;
$color-status-nonbillable-text: $color-white;

$color-status-billable: #007A00;
$color-status-billable-dark: #007a00;
$color-status-billable-light: #C0F1C0;
$color-status-billable-lighter: #ebffeb;
$color-status-billable-text: $color-white;

$color-status-disputed: #ff7d19;
$color-status-disputed-dark: #a34700;
$color-status-disputed-light: #ffc599;
$color-status-disputed-lighter: #ffe7d6;
$color-status-disputed-text: $color-black;

$color-code-dark-1: #1631ca;
$color-code-dark-2: #c81d25;
$color-code-dark-3: #0e7b81;
$color-code-dark-4: #e000a8;
$color-code-dark-5: #355834;
$color-code-dark-6: #ab3428;
$color-code-dark-7: #0b3954;
$color-code-dark-8: #8f2d56;
$color-code-dark-9: #093824;
$color-code-dark-10: #b84300;
$color-code-dark-11: #227395;
$color-code-dark-12: #8f5100;
$color-code-dark-13: #2e1f27;
$color-code-dark-14: #d30d4c;
$color-code-dark-15: #075f5f;
$color-code-dark-16: #4f6714;
$color-code-dark-17: #534d41;
$color-code-dark-18: #8a3719;
$color-code-dark-19: #267d30;
$color-code-dark-20: #525174;
$color-code-dark-21: #4E4E4F;


$color-event-status-cancelled: #A30000;
$color-event-status-cancelled-light: #FFD6D6;
$color-event-status-closed: #086375;
$color-event-status-closed-light: #D9F6FC;
$color-event-status-completed: #081C75;
$color-event-status-completed-light: #D9DFFC;
$color-event-status-open: #49B800;
$color-event-status-open-light: #E7FFD6;
$color-event-status-test: #802392;
$color-event-status-test-light: #F3DEF7;


$color-event-type-breakfast: #3B60A5;
$color-event-type-breakfast-light: #E1E8F4;
$color-event-type-breakfast-lighter: #F0F4F9;
$color-event-type-continental-breakfast: #7E5211;
$color-event-type-continental-breakfast-light: #FAEEDB;
$color-event-type-continental-breakfast-lighter: #FDF7ED;
$color-event-type-dinner: #B72A3F;
$color-event-type-dinner-light: #F7DEE2;
$color-event-type-dinner-lighter: #FBEFF1;
$color-event-type-hors-doeuvres: #602F2F;
$color-event-type-hors-doeuvres-light: #EBD6D6;
$color-event-type-hors-doeuvres-lighter: #F2E4E4;
$color-event-type-lunch: #003166;
$color-event-type-lunch-light: #D6EAFF;
$color-event-type-lunch-lighter: #EBF4FF;
$color-event-type-other: #3E1334;
$color-event-type-other-light: #F5E0F0;
$color-event-type-other-lighter: #FAF0F8;
$color-event-type-refreshments: #2B7869;
$color-event-type-refreshments-light: #F0FAF8;
$color-event-type-refreshments-lighter: #F5FCFA;
$color-event-type-live-webinar: #4A3445;
$color-event-type-live-webinar-light: #EEE7EC;
$color-event-type-live-webinar-lighter: #F7F3F6;

$color-section-divider: #E5E5E5;
$color-section-scroller-outline:#B4D0DC;
$color-section-guests-header:#73A8BD;



/* Leadstar Brand Colors */
$color-brand-leadstar-primary: #3b8ca6;
$color-brand-leadstar-secondary: #8cb1c3;
$color-brand-leadstar-secondary-lighter: #e9eff4;