.u--hide-medium-up {
    @media (min-width: 961px) {
        display: none;
    }
}

.u--hide-small-down {
    @media (max-width: 960px) {
        display: none;
    }
}

.u--mobile-only {
    @media (min-width: 601px) {
        display: none;
    }
}

.u--non-mobile {
    @media (max-width: 600px) {
        display: none;
    }
}