@import 'variables.scss'; //PCNG-1556
@import 'utility.scss'; //PCNG-2276
@import 'typography.scss';
@import 'components/button.scss';
@import 'components/cards.scss';
@import 'components/confirmation-meter.scss'; //PCNG-2772
@import 'components/dialog.scss';
@import 'components/informational.scss';
@import 'components/input-control.scss';
@import 'components/pin.scss';
@import 'components/standalone.scss';
@import 'components/nestedDialog.scss';


@font-face {
    font-family: 'open sans';
    src: url('./fonts/OpenSans-Medium.ttf');
}
html,
body {
    overflow-x: hidden;
    @media screen and (max-width: 630px) {
        overflow-x: visible;
    }
    height: 100%;
    font-family: 'open sans' -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol" !important;
}

.p-menu ul {
    width: 200px !important;
    height: 1870px !important;
}
.sidebar {
    .p-component,
    .p-component * {
        background-color: #1a495c !important;
        border-radius: 6px !important;
        color: white !important;
    }
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #1a495c !important;
}
.p-menu .p-menuitem-link:focus {
    outline: none !important;
    outline-offset: none !important;
    box-shadow: none !important;
}

a.p-menu .p-menuitem-link:active {
    color: #6aa2b9 !important;
}
.p-menuitem-link-active {
    color: #6aa2b9 !important;
    
    font-weight: bold;
    .p-menuitem-text {
        color: #6aa2b9 !important;
        
        font-weight: bold;
    }
    .p-menuitem-icon {
        color: #6aa2b9 !important;
    }
}

.p-menu {
    padding: 0px !important;
    
    font-weight: bold;
}


.Padd {
    padding-top: 20px;
    padding-left: 20px;
}

.p-menu .p-menuitem-link .p-menuitem-text {
    text-transform: uppercase !important;
}

.p-menu {
    
    font-weight: bold;
    font-size: 12px !important;
    line-height: 13px !important;
    width: 91px !important;
    text-align: left !important;
    border-radius: 10px !important;
}
.p-menu .p-menuitem {
    margin: 0;
    border-radius: 0px !important;
    border: 1px solid #42616d;
    
    font-weight: bold;
}

.white-bg {
    background-color: #ffff;
}

html,
body {
    margin: 0;
    height: 100%;
}

body {
    
}

:host ::ng-deep {
    .first-icon {
        background-image: url("/src/assets/menu/samp.jpg") !important;
        background-size: 1em !important;
        width: 1em !important;
        height: 1em !important;
    }
}

.form-control:focus {
    outline: 0 !important;
    box-shadow: none !important;
    background: var(--medium);
  }

.dashboard {
    color: #1a495c;
    font-size: 1.875rem;
    letter-spacing: -0.5px;
    line-height: 2.6875rem;
    text-align: left;
    font-weight: bold;
}

.show {
    display: none !important;
}

.p-button:enabled:hover {
    background: #1a495c;
    color: var(--light);
    border-color: #1a495c;
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }

:host ::ng-deep .p-dropdown {
    color: #4e4e4e;
    font-family: Open Sans;
    font-size: 14px;
    letter-spacing: 0.05px;
    line-height: 17px;
    text-align: left;
}

:host ::ng-deep .p-dropdown-trigger {
    color: #4e4e4e;
    font-family: Open Sans;
    font-size: 14px;
    letter-spacing: 0.05px;
    line-height: 17px;
    text-align: left;
}

p-checkbox {
    display: inline-flex;
    vertical-align: baseline;
    align-items: center;
}

:host ::ng-deep {

    .p-dropdown {
        background-color: var(--medium);
        border: 1px solid var(--comp-border) !important;
        border-radius: 4px;
        width: 140px;
        height: 2.8rem;
      }
    
      .p-dropdown .p-dropdown-trigger {
        background-color: var(--medium) !important;
        color: #6AA2B9;
      }
    
      .p-dropdown .p-dropdown-trigger {
        color: #6AA2B9;
        border-left: 1px solid var(--comp-border);
      }
    
  
      .p-multiselect-trigger-icon {
        font-size: 0.7rem;
        color: #8b8787;
    }
    
    .p-multiselect .p-multiselect-trigger {
        border-left: none;
        color: #8b8787;
        font-size: 0.7rem;
        width: 1.7rem;
    }
    .element {
        position: relative;
    }
    .menu > ul > li > ul {
        position: absolute;
        background-color: #555;
        width: 100%;
        left: 0;
        top: 100%;
        z-index: 1;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        position: absolute;
        background-color: #555;
        width: 100%;
        left: 0;
        top: 100%;
        z-index: 1;
    }
}

/* not selected */
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: var(--light);
    border: 1px solid var(--comp-border);
    border-radius: 6px;
    width: 201px;
    height: 50px;
    padding-left: 11px;
    padding-right: 11px;
    color: #1a495c;
    justify-content: center;

    position: relative;

    &:before {
        content: '';
        position: absolute;
        height: 6px;
        width: 6px;
        background-color: transparent;
        bottom: 3px;
        transform: translateX(50%) rotateZ(45deg);
    }

}

/* selected */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: white;
    // background-image: url("/assets/images/TabBtn.svg") !important;
    // background-repeat: no-repeat;
    border: none;
    background: none;
    border-color: none;
    // height: 56px;
    margin: 0;
    outline: none !important;
    // padding-top: 7px;
    justify-content: center;

    background-color: var(--primary);
    overflow: visible;

    &:before {
        // content: '';
        // position: absolute;
        // height: 6px;
        // width: 6px;
        background-color: var(--primary);
        bottom: -3px;
        // transform: translateX(50%) rotateZ(45deg);
    }
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:focus {
    outline: none;
}

/* hover */
.p-tabview .p-tabview-nav li:not(.p-disabled):hover .p-tabview-nav-link {
    text-decoration: none;
}

.container-dd {
    width: 100%;
    height: 45px;
    background: var(--medium);
    position: relative;
    display: inline-block;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
}

.container-dd-white {
  width: 240px;
  height: 45px;
 // background: var(--light);
  position: relative;
  display: inline-block;
  border-radius: 2px;
  //border: 1px solid #d9d9d9;

}

.container-dd select {
//   -webkit-appearance: none;
// -moz-appearance: none;
// appearance: none;
    border: none;
  background: transparent;
  height: 45px;
  color: #666666;
  font-size: 0.8rem;
  font-weight: 900;
  margin-top: 3px;
}

.container-dd select option {
  font-size: 0.9rem;
  font-weight: 500;
}






.dd-ico {
  position: absolute;
  right: 10px;
  top: 15px;
  color: #848484;
}
.dd-ico-white {
    position: absolute;
    right: 5px;
    top: 16px;
    color: #848484;
  }
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
}

.p-tabview .p-tabview-panels {
    background: none;
    padding: 0rem;
    border: 0px solid #c8c8c8;
    color: #1a495c;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.p-tabview .p-tabview-nav {
    background: transparent;
    border: 0 none;
    border-width: 1px;
    margin-left: 20px;
    margin-top: 21px;


    margin-bottom: 8px;
}

.p-tabview .p-tabview-nav li {
    margin-right: 10px;
}
.p-button.p-button-link:enabled:focus {
    background: transparent;
    box-shadow: none;
    border-color: transparent;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
/* For desktop: */
.col-1 {
    width: 8.33%;
}
.col-2 {
    width: 16.66%;
}
.col-3 {
    width: 25%;
}
.col-4 {
    width: 33.33%;
}
.col-5 {
    width: 41.66%;
}
.col-6 {
    width: 50%;
}
.col-7 {
    width: 58.33%;
}
.col-8 {
    width: 66.66%;
}
.col-9 {
    width: 75%;
}
.col-10 {
    width: 83.33%;
}
.col-11 {
    width: 91.66%;
}
.col-12 {
    width: 100%;
}
::-webkit-scrollbar {
    background: var(--gray-100) !important
}

::-webkit-scrollbar-thumb {

    background: var(--gray-300)  !important;

    border-radius: 2px !important;

}
.p-component {
    font-family: "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

//PCNG-1556
.zoomed-content {
    zoom: $zoomMainSmall;
    @media (min-width: $widthTabletBreak) {
        zoom: $zoomMain;
    }
    @media (min-width: $widthLargeBreak) {
        zoom: $zoomMainLarge;
    }
    @media (min-width: $widthExtraLargeBreak) {
        zoom: $zoomMainExtraLarge;
    }
}

.headerSmall {
    display: block;
    @media (min-width: $widthMenuBreak) {
        display: none;
    }
}

.headerLarge {
    display: none;
    @media (min-width: $widthMenuBreak) {
        display: block;
    }
}

// @media only screen and (max-width: $widthMenuPreBreak) {
//     .hub-content {
//         padding: 50px 0 1rem 0;
//     }
// }
    
.hub-content {
    width: calc(100% - 16px);
    margin: auto;
    padding: 50px 0 1rem 0;
    @media (min-width: $widthMobileBreak) {
        padding: 70px 0 1rem 0;
    }
    @media (min-width: $widthMenuBreak) {
        width: calc(100% - 195px);
        margin-right: 0; //PCNG-1595
        padding: 0 0 1rem 0;
        margin-top: 36px;
    }
}
    