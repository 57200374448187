.standalone {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
    &__header-bar {
        background-color: $color-primary;
        color: $color-white;
        padding: 10px 0;
    }
    &__header {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 30px;
        align-items: center;
        padding: 10px 15px;
    }
    &__logo {
        max-height: 30px;
        width: auto;
        height: auto;
    }
    &__header, &__content {
        margin: auto;
        max-width: 1400px;
        width: 95%;
    }
    &__title {
        margin: 0;
        font-size: 24px;
        line-height: 1;
        text-align: right;
    }
    @media (min-width: $widthTabletBreak) {
        gap: 30px;
        padding-bottom: 30px;
        &__header {
            padding: 10px 0;
        }
        &__logo {
            grid-row: 1 / 2;
            grid-column: 1 / 2;
        }
        &__title {
            text-align: center;
            grid-column: 1 / 3;
            grid-row: 1 / 2;
        }
        &__content {
            border: 1px solid $color-secondary;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        }
    }
}