// PCNG-2772
.confirmation-meter {
    max-width: 400px;
    &__title {
        color: $color-extra;
        font-weight: 700;
        font-size: 13px;
        display: flex;
        flex-direction: row;
        gap: 2px;
        color: $color-primary;
        text-align: right;
        justify-content: space-between;
    }
    &__bar {
        position: relative;
        height: 20px;
        background-color: $color-secondary-lighter;
        border: 1px solid $color-primary;
        border-radius: $border-radius-card;
    }
    &__fill {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: $color-status-checked-in;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &--full {
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;    
        }
    }
    &__inaccuracy{
        color: $color-error;
    }
}