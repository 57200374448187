
.nested-dialog{
    &.p-dialog {
        width: 700px !important;
        max-width: 95%;
        border: none;
        border-radius: 5px;
    
        .p-dialog-header {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border: 1px solid $color-primary;
            width: 100%;
            background: white;
            color: #333333;
            padding: 10px 10px;
    
            .p-dialog-title {
                font-weight: 900;
                font-size: 18px !important;
                color: $color-primary;
                margin-top: revert;
                width: 100%;
                font-weight: 800;
                font-family: "Open Sans", sans-serif;
                line-height: 1;
            }
        }
    
        .p-dialog-content {
            padding: 10px;
            border: 1px solid $color-primary;
            border-top: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            overflow-y: hidden !important;
            font-size: 14px;
            line-height: 22px;
    
            .dialog__buttons {
                display: flex;
                justify-content: flex-end;
                column-gap: 10px;
            }
        }
    
    }
    
    .p-dialog-header-close-icon {
        border: 2px solid var(--primary);
        background-color: var(--white);
        border-radius: 13px;
        height: 26px;
        width: 26px;
        line-height: 20px;
        font-size: 15px;
        margin: -2px;
        padding: 1px;
        padding-left: 4px;
        color: var(--secondary);
    }
    
}

