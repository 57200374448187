.announcement {
    border-radius: 5px;
    font-size: 14px;
    line-height: 1.4;
    display: grid;
    grid-template-columns: 1fr;
    // border: 3px solid $color-extra;
    border: 1px solid $color-secondary;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    &__header {
        // background-color: $color-extra;
        background-color: $color-secondary-lighter;
        // color: $color-white;
        color: $color-primary;
        padding: 10px;
        display: grid;
        grid-template-columns: auto 1fr auto;
        column-gap: 10px;
        align-items: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    &__body {
        // background-color: $color-accent-light;
        background-color: $color-white;
        padding: 10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        line-height: 1.6;
    }
    &__icon {
        width: 35px;
        height: auto;
    }
    &__title {
        font-size: 18px;
        font-weight: 700;
    }
    &__link {
        text-decoration: underline;
        transition: .3s all linear;
        color: $color-extra;
        font-weight: 700;
        &:hover {
            color: $color-primary;
        }
    }
    &__toggle {
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            font-weight: 700;
        }
    }
    p {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    strong {
        color: $color-extra;
    }
}

.informational {
    border-radius: 5px;
    font-size: 16px;
    line-height: 1.4;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: stretch;
    border: 3px solid;
    &__plain {
        color: $color-gray-dark;
    }
    &__icon-wrapper, &__content {
        padding: 10px;
    }
    &__icon-wrapper {
        border-right: 3px solid;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    &__icon {
        width: 35px;
        height: auto;
    }
    &__content {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        align-self: center;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    &__title {
        font-weight: 700;
    }
    &--default {
        border-color: $color-primary;
        color: $color-primary;
        font-weight: 600;
        .informational__icon-wrapper {
            background-color: $color-secondary-lighter;
        }
    }
    &--basic {
        border-color: $color-primary;
        color: $color-primary;
        font-weight: 600;
        font-size: 13px;
        background-color: $color-white;
        .informational__icon {
            width: 30px;
        }
        .informational__icon-wrapper {
            background-color: $color-secondary-lighter;
        }
    }
    &--accent {
        border-color: $color-accent;
        color: $color-accent-dark;
        font-weight: 700;
        .informational__icon-wrapper {
            background-color: $color-accent-light;
            border-color: $color-accent;
        }
    }
    &--extra {
        border-color: $color-extra;
        color: $color-extra;
        font-weight: 600;
        .informational__icon-wrapper {
            background-color: rgba($color-extra, 0.1);
        }
    }
    &--small-icon {
        .informational__icon {
            width: 25px;
        }
    }
}


.info-paragraph {
    display: flex;
    gap: 10px;
    img {
        width: auto;
        height: 25px;
    }
}

.info-tutorial {
    padding: 5px 10px;
    background-color: $color-secondary-lighter;
    color: $color-primary;
    border-radius: $border-radius-card;
    border: 1px solid $color-secondary;
    &__link {
        text-decoration: underline;
    }
}


/* START - LeadStar Branding */
.brand--leadstar {
    .announcement {
        border-color: $color-brand-leadstar-secondary;
        &__header {
            background-color: $color-brand-leadstar-secondary-lighter;
            color: $color-brand-leadstar-primary;
        }
        &__link {
            &:hover {
                color: $color-brand-leadstar-primary;
            }
        }
    }    
    .informational {
        &--default {
            border-color: $color-brand-leadstar-primary;
            color: $color-brand-leadstar-primary;
            .informational__icon-wrapper {
                background-color: $color-brand-leadstar-secondary-lighter;
            }
        }
        &--basic {
            border-color: $color-brand-leadstar-primary;
            color: $color-brand-leadstar-primary;
            .informational__icon-wrapper {
                background-color: $color-brand-leadstar-secondary-lighter;
            }
        }
    }
    .info-tutorial {
        background-color: $color-brand-leadstar-secondary-lighter;
        color: $color-brand-leadstar-primary;
        border-color: $color-brand-leadstar-secondary;
    }
}
/* END - LeadStar Branding */




.informational {
    &--default {
        border-color: $color-primary;
        color: $color-primary;
        .informational__icon-wrapper {
            background-color: $color-secondary-lighter;
        }
    }
    &--basic {
        border-color: $color-primary;
        color: $color-primary;
        background-color: $color-white;
        .informational__icon-wrapper {
            background-color: $color-secondary-lighter;
        }
    }
    &--accent {
        border-color: $color-accent;
        color: $color-accent-dark;
        .informational__icon-wrapper {
            background-color: $color-accent-light;
            border-color: $color-accent;
        }
    }
    &--extra {
        border-color: $color-extra;
        color: $color-extra;
        .informational__icon-wrapper {
            background-color: rgba($color-extra, 0.1);
        }
    }
}


