.button {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    padding: 15px 25px;
    border-radius: $border-radius-button !important;
    border: 1px solid;
    width: 100%;
    cursor: pointer;
    transition: .3s linear all;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    &--small {
        padding: 10px 15px;
    }
    &--primary {
        background-color: $color-primary;
        color: $color-white;
        border-color: $color-primary;
        &:hover, &:focus {
            background-color: $color-primary-light;
        }
        &:disabled, &.disabled {
            border-color: $color-gray;
            background-color: $color-gray;
            // color: $color-gray-lighter;
        }
    }
    &--secondary {
        background-color: $color-secondary-lighter;
        color: $color-primary;
        border-color: $color-secondary;
        &:hover, &:focus {
            background-color: $color-secondary-light;
        }
        &:disabled, &.disabled {
            background-color: $color-secondary-lighter;
            color: $color-gray;
            border-color: $color-gray;
        }
    }
    &--ghost {
        background-color: $color-white;
        color: $color-primary;
        border-color: $color-secondary;
        &:hover, &:focus {
            background-color: $color-secondary-lighter;
        }
        &:disabled, &.disabled {
            background-color: $color-white;
            color: $color-gray;
            border-color: $color-gray;
        }
    }
    &--accent {
        background-color: $color-accent-light;
        color: $color-accent-dark;
        border-color: $color-accent;
        &:hover, &:focus {
            background-color: $color-accent-dark;
            color: $color-white;
            border-color: $color-accent-dark;
        }
        &:disabled, &.disabled {
            background-color: $color-accent-light;
            color: $color-gray;
            border-color: $color-gray;
        }
    }
    &--icon {
        padding: 10px 15px;
        line-height: 1;
        display: flex;
        justify-content: center;
        gap: 10px;
        font-size: 15px;
        span {
            padding-top: 3px;
        }
    }
    &--check-in {
        background-color: $color-status-checked-in;
        color: $color-white;
        border-color: $color-status-checked-in;
        &:hover, &:focus {
            background-color: darken($color-status-checked-in, 10%);
        }
        &:disabled, &.disabled {
            border-color: $color-gray;
            background-color: $color-gray;
        }
    }
    &--cancel {
        background-color: $color-status-cancelled;
        color: $color-white;
        border-color: $color-status-cancelled;
        &:hover, &:focus {
            background-color: darken($color-status-cancelled, 10%);
        }
        &:disabled, &.disabled {
            border-color: $color-gray;
            background-color: $color-gray;
        }
    }
    &--toggle {
        border-color: $color-secondary;
        background-color: #fff;
        color: $color-primary;
        transition: .3s linear all;
        &:hover {
            background-color: $color-secondary-lighter;
            color: $color-primary;
            border-color: $color-primary;
        }
        &.active {
            background-color: $color-primary;
            color: $color-white;
            border-color: $color-primary;
        }
    }
    &--tab {
        line-height: 1.4;
        padding: 10px 15px;
        text-transform: uppercase;
    }
    &--toggle-bar.button--toggle.button {
        .button__highlight {
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 2px 10px;
            // background-color: $color-gray-light;
            background-color: $color-background-highlight;
            color: $color-primary;
            transition: .3s all linear;
            border: 1px solid $color-secondary;
        }
        &.active {
            .button__highlight {
                // background-color: darken($color-primary, 25%);
                border-color: $color-primary;
            }
        }
        &:first-child, &:first-child:focus, &:first-child:active, &:first-child.active {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            outline: none !important;
        }
        &:last-child, &:last-child:focus, &:last-child:active, &:last-child.active {
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            outline: none !important;
        }        
    }
    &:disabled, &.disabled {
        cursor: default;
    }
    &:hover, &:focus {
        box-shadow: none;
    }
    &__icon {
        max-height: 25px;
        width: auto;
    }
}

.expander {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    letter-spacing: 1px;
    padding: 8px 15px;
    border-radius: 20px !important;
    border: 1px solid !important;
    cursor: pointer;
    transition: .3s linear all;
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
    width: 120px;
    background-color: $color-primary;
    color: $color-white !important;
    border-color: $color-primary !important;
    &:hover {
        background-color: $color-tertiary-light;
        background-color: $color-primary-light;
    }
    &:focus {
        outline: none;
        border: 1px solid $color-primary;
    }
    &__icon {
        height: 22px;
        width: auto;
    }
    &--active {
        background-color: $color-secondary-lighter;
        color: $color-primary !important;
        font-weight: 600;
        &:hover {
            background-color: $color-secondary-light;
            border-color: $color-primary;
        }
    }
    &--wide {
        width: 200px;
    }
}

.icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border-radius: 5px;
    transition: .3s linear all;
    cursor: default;
    border: 1px solid transparent;
    width: 40px;
    height: 40px;
    img {
        max-width: 35px;
        max-height: 35px;
    }
    &--25 {
        width: 25px;
        height: 25px;
        img {
            max-width: 20px;
            max-height: 20px;
        }
    }
    &--30 {
        width: 30px;
        height: 30px;
        img {
            max-width: 25px;
            max-height: 25px;
        }
    }
    &--35 {
        width: 35px;
        height: 35px;
        img {
            max-width: 30px;
            max-height: 30px;
        }
    }
    &--40-30 {
        width: 40px;
        height: 40px;
        img {
            max-width: 35px;
            max-height: 35px;
        }
        @media (min-width: $widthLaptopBreak) {
            width: 30px;
            height: 30px;
            img {
                max-width: 25px;
                max-height: 25px;
            }
        }
    }
    &--40 {
        width: 40px;
        height: 40px;
        img {
            max-width: 35px;
            max-height: 35px;
        }
    }
    &--no-padding {
        padding: 0;
        justify-content: flex-start;
    }
    &.enabled {
        cursor: pointer;
        &:hover {
            background-color: $color-secondary-lighter;
            border-color: $color-primary;
        }
        &.cancelled {
            &:hover {
                background-color: $color-status-cancelled-lighter;
                border-color: $color-status-cancelled;
            }    
        }
        &.checked-in {
            &:hover {
                background-color: $color-status-checked-in-lighter;
                border-color: $color-status-checked-in;
            }    
        }
        &.disqualified {
            &:hover {
                background-color: $color-status-disqualified-lighter;
                border-color: $color-status-disqualified;
            }    
        }
        &.future-event {
            &:hover {
                background-color: $color-status-future-event-lighter;
                border-color: $color-status-future-event;
            }    
        }
        &.wait-list {
            &:hover {
                background-color: $color-status-wait-list-lighter;
                border-color: $color-status-wait-list;
            }    
        }
    }
    &.active {
        background-color: $color-primary;
        &.enabled {
            &:hover {
                background-color: $color-primary-light;
                border-color: $color-primary;
            }
        }
        &.cancelled {
            background-color: $color-status-cancelled;
            &.enabled {
                &:hover {
                    background-color: lighten($color-status-cancelled, 15%);
                    border-color: $color-status-cancelled;
                }
            } 
        }
        &.checked-in {
            background-color: $color-status-checked-in;
            &.enabled {
                &:hover {
                    background-color: lighten($color-status-checked-in, 15%);
                    border-color: $color-status-checked-in;
                }
            } 
        }
        &.disqualified {
            background-color: $color-status-disqualified;
            &.enabled {
                &:hover {
                    background-color: lighten($color-status-disqualified, 15%);
                    border-color: $color-status-disqualified;
                }
            }
        }
        &.future-event {
            background-color: $color-status-future-event;
            &.enabled {
                &:hover {
                    background-color: lighten($color-status-future-event, 15%);
                    border-color: $color-status-future-event;
                }
            }
        }
        &.wait-list {
            background-color: $color-status-wait-list;
            &.enabled {
                &:hover {
                    background-color: lighten($color-status-wait-list, 15%);
                    border-color: $color-status-wait-list;
                }
            }
        }
    }
}

.tab-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.3;
    padding: 15px 10px;
    border-radius: $border-radius-button !important;
    border: 1px solid;
    width: 100%;
    max-width: 280px;
    cursor: pointer;
    transition: .3s linear all;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    background-color: $color-white;
    border-color: $color-secondary;
    color: $color-primary;
    position: relative;
    z-index: 50;
    &:before {
        content: "";
        position: absolute;
        height: 8px;
        width: 8px;
        background-color: transparent;
        bottom: -4px;
        left: calc(50% - 10px);
        clip-path: polygon(0 100%,100% 100%,100% 0);
        transform: translateX(50%) rotateZ(45deg);
        transition: .3s linear all;
        z-index: -1;
    }
    &:hover {
        background-color: $color-secondary-lighter;
    }
    &--active {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;
        &:hover {
            background-color: $color-primary;
        }
        &:before {
            background-color: $color-primary;
        }
    }
    &--disabled {
        cursor:default;
        border-color: $color-gray;
        background-color: $color-gray;
        color: $color-white;
        &:hover {
            background-color: $color-gray;
        }
    }
    &:focus {
        outline: none;
    }
    @media (min-width: 768px) {
        &:before {
            height: 10px;
            width: 10px;
            bottom: -5px;    
        }
    }
}



.brand--leadstar {
    .button {
        &--primary {
            background-color: $color-brand-leadstar-primary;
            border-color: $color-brand-leadstar-primary;
            &:hover, &:focus {
                background-color: darken($color-brand-leadstar-primary, 15%);
            }
            &:disabled, &.disabled {
                border-color: $color-gray;
                background-color: $color-gray;
            }
        }
        &--secondary {
            background-color: $color-brand-leadstar-secondary-lighter;
            color: $color-brand-leadstar-primary;
            border-color: $color-brand-leadstar-secondary;
            &:hover, &:focus {
                background-color: lighten($color-brand-leadstar-secondary, 30%);
            }
            &:disabled, &.disabled {
                background-color: $color-brand-leadstar-secondary-lighter;
                color: $color-gray;
                border-color: $color-gray;
            }
        }
        &--ghost {
            background-color: $color-white;
            color: $color-brand-leadstar-primary;
            border-color: $color-brand-leadstar-secondary;
            &:hover, &:focus {
                background-color: $color-brand-leadstar-secondary-lighter;
            }
            &:disabled, &.disabled {
                background-color: $color-white;
                color: $color-gray;
                border-color: $color-gray;
            }
        }
        &--toggle {
            border-color: $color-brand-leadstar-secondary;
            color: $color-brand-leadstar-primary;
            &:hover {
                background-color: $color-brand-leadstar-secondary-lighter;
                color: $color-brand-leadstar-primary;
                border-color: $color-brand-leadstar-primary;
            }
            &.active {
                background-color: $color-brand-leadstar-primary;
                color: $color-white;
                border-color: $color-brand-leadstar-primary;
            }
        }
        &--toggle-bar.button--toggle.button {
            .button__highlight {
                background-color: $color-background-highlight;
                color: $color-brand-leadstar-primary;
                border-color: $color-brand-leadstar-secondary;
            }
            &.active {
                .button__highlight {
                    border-color: $color-brand-leadstar-primary;
                }
            }
        }
    }  
    .expander {
        background-color: $color-brand-leadstar-primary;
        border-color: $color-brand-leadstar-primary !important;
        &:hover {
            background-color: $color-tertiary-light;
            background-color: darken($color-brand-leadstar-primary, 15%);
        }
        &:focus {
            border: 1px solid $color-brand-leadstar-primary;
        }
        &--active {
            background-color: $color-brand-leadstar-secondary-lighter;
            color: $color-brand-leadstar-primary !important;
            &:hover {
                background-color: lighten($color-brand-leadstar-secondary, 30%);
                border-color: $color-brand-leadstar-primary;
            }
        }
    }    
    .icon-button {
        &.enabled {
            &:hover {
                background-color: $color-brand-leadstar-secondary-lighter;
                border-color: $color-brand-leadstar-primary;
            }
            &.cancelled {
                &:hover {
                    background-color: $color-status-cancelled-lighter;
                    border-color: $color-status-cancelled;
                }    
            }
            &.checked-in {
                &:hover {
                    background-color: $color-status-checked-in-lighter;
                    border-color: $color-status-checked-in;
                }    
            }
            &.disqualified {
                &:hover {
                    background-color: $color-status-disqualified-lighter;
                    border-color: $color-status-disqualified;
                }    
            }
            &.future-event {
                &:hover {
                    background-color: $color-status-future-event-lighter;
                    border-color: $color-status-future-event;
                }    
            }
            &.wait-list {
                &:hover {
                    background-color: $color-status-wait-list-lighter;
                    border-color: $color-status-wait-list;
                }    
            }
        }
        &.active {
            background-color: $color-brand-leadstar-primary;
            &.enabled {
                &:hover {
                    background-color: $color-brand-leadstar-secondary-lighter;
                    border-color: $color-brand-leadstar-primary;
                }
            }
            &.cancelled {
                background-color: $color-status-cancelled;
                &.enabled {
                    &:hover {
                        background-color: lighten($color-status-cancelled, 15%);
                        border-color: $color-status-cancelled;
                    }
                } 
            }
            &.checked-in {
                background-color: $color-status-checked-in;
                &.enabled {
                    &:hover {
                        background-color: lighten($color-status-checked-in, 15%);
                        border-color: $color-status-checked-in;
                    }
                } 
            }
            &.disqualified {
                background-color: $color-status-disqualified;
                &.enabled {
                    &:hover {
                        background-color: lighten($color-status-disqualified, 15%);
                        border-color: $color-status-disqualified;
                    }
                }
            }
            &.future-event {
                background-color: $color-status-future-event;
                &.enabled {
                    &:hover {
                        background-color: lighten($color-status-future-event, 15%);
                        border-color: $color-status-future-event;
                    }
                }
            }
            &.wait-list {
                background-color: $color-status-wait-list;
                &.enabled {
                    &:hover {
                        background-color: lighten($color-status-wait-list, 15%);
                        border-color: $color-status-wait-list;
                    }
                }
            }
        }
    }
    
    .tab-button {
        border-color: $color-brand-leadstar-secondary;
        color: $color-brand-leadstar-primary;
        &:hover {
            background-color: $color-brand-leadstar-secondary-lighter;
        }
        &--active {
            background-color: $color-brand-leadstar-primary;
            border-color: $color-brand-leadstar-primary;
            &:hover {
                background-color: $color-brand-leadstar-primary;
            }
            &:before {
                background-color: $color-brand-leadstar-primary;
            }
        }
    }
    
}