.pin-entry {
    border: 2px solid $color-secondary;
    border-radius: 5px;
    background-color: $color-background-highlight;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 30px 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-weight: 700;

    &__prompt-text {
        color: $color-extra;
        font-size: 16px;
    }

    &__error-text {
        color: $color-accent;
        font-size: 14px;
    }

}
  