.dialog-header {
    display: flex;
    align-items: center;
    gap: 10px;
    h2 {
        margin: 0;
        color: $color-primary;
        font-weight: 900;
        font-size: 20px;
        font-family: $font-family-main;
        line-height: 1;
    }
}

.brand--leadstar {
    .dialog-header {
        h2 {
            color: $color-brand-leadstar-primary;
        }
    }
}